
import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import andivi_img from "../assets/img/andivi_img.png"; 
import andivi_sign from "../assets/img/andivi_sign.png"; 
import andivi_msg from "../assets/img/andivi_msg.png"; 
import andivi_ig from "../assets/img/andivi_ig.png"; 
import andivi_wa from "../assets/img/andivi_wa.png"; 


export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row>
            <TrackVisibility>
                {({ isVisible }) => (
                    <div className="animate__animated banner">
                        <div className={`animate__animated animate__zoomIn ${isVisible ? 'animate__slideInUp' : ''}`}>
                            <div className="d-flex align-items-center justify-content-center">
                                {/* Versión de escritorio: Imagen a la izquierda, imágenes de redes sociales a la derecha */}
                                <Row className="align-items-center d-none d-md-flex">
                                <Col xs={6} className="text-center">
                                    <div className="d-flex flex-column align-items-center">
                                    <img 
                                        src={andivi_sign}
                                        alt="Imagen 1"
                                        className="custom-image-sign"
                                    />
                                    {/* Imágenes de redes sociales */}
                                    <div className="d-flex justify-content-center mt-3">
                                        <a href="https://mail.google.com/mail/?view=cm&to=andivi.panaderia@gmail.com">
                                            <img
                                            src={andivi_msg}
                                            alt="Red Social 1"
                                            className="custom-image-social"
                                            />
                                        </a>
                                        <a href="https://www.instagram.com/andivi_panaderia/">
                                            <img
                                            src={andivi_ig}
                                            alt="Red Social 2"
                                            className="custom-image-social"
                                            />
                                        </a>
                                        <a href="https://wa.me/+525543653652">
                                            <img
                                            src={andivi_wa}
                                            alt="Red Social 3"
                                            className="custom-image-social"
                                            />
                                        </a>
                                    </div>
                                    </div>
                                </Col>
                                <Col xs={6} className="text-center">
                                    <img
                                    src={andivi_img}
                                    alt="Imagen 2"
                                    className="custom-image-style"
                                    />
                                </Col>
                                </Row>

                                {/* Versión móvil: Imágenes de redes sociales arriba, imagen a la derecha */}
                                <Row className="align-items-center d-md-none">
                                <Col xs={12} className="text-center">
                                
                                    <img 
                                        src={andivi_sign}
                                        alt="Imagen 1"
                                        className="custom-image-sign"
                                    />
                                    <img
                                    src={andivi_img}
                                    alt="Imagen 2"
                                    className="custom-image-style"
                                    />
                                    <div className="d-flex justify-content-center mb-3">
                                        <a href="mailto:andivi.panaderia@gmail.com">
                                            <img
                                                src={andivi_msg}
                                                alt="Red Social 1"
                                                className="custom-image-social"
                                            />
                                        </a>
                                        <a href="https://www.instagram.com/andivi_panaderia/">
                                            <img
                                                src={andivi_ig}
                                                alt="Red Social 2"
                                                className="custom-image-social"
                                            />
                                        </a>
                                        <a href="https://wa.me/+525543653652">
                                            <img
                                                src={andivi_wa}
                                                alt="Red Social 3"
                                                className="custom-image-social"
                                            />
                                        </a>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )}
            </TrackVisibility>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
